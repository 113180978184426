import React from 'react'
import { Link } from 'react-router-dom';

import {
    Container,
    Row,
    Col
} from 'reactstrap';

import Isvg from 'react-inlinesvg';
import logoFooterIcon from 'assets/svg/logo-footer.svg';

const Footer = () => {
    return (
        <footer className='footer'>
            <div className='footer-upper'>
                <Container>
                    <Row>
                        <Col lg="3">
                            <div className='widget-logo'>
                                <Isvg src={logoFooterIcon} alt='logo' />
                            </div>
                        </Col>
                        <Col lg="3">
                            <div className='widget-list'>
                                <h6>TELECOMS</h6>
                                <ul>
                                    <li>
                                        <Link to='/'>Solutions</Link>
                                    </li>
                                    <li>
                                        <Link to='/'>Managed Services</Link>
                                    </li>
                                    <li>
                                        <Link to='/'>Professional Services</Link>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg="3">
                            <div className='widget-list'>
                                <h6>Modern Workplace</h6>
                                <ul>
                                    <li>
                                        <Link to='/'>Product manuals</Link>
                                    </li>
                                    <li>
                                        <Link to='/'>White papers</Link>
                                    </li>
                                    <li>
                                        <Link to='/'>Contoso Apps</Link>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg="3">
                            <div className='widget-list'>
                                <h6>COMPANY</h6>
                                <ul>
                                    <li>
                                        <Link to='/'>Careers at Contoso</Link>
                                    </li>
                                    <li>
                                        <Link to='/'>About Contoso</Link>
                                    </li>
                                    <li>
                                        <Link to='/'>Company News</Link>
                                    </li>
                                    <li>
                                        <Link to='/'>Site map</Link>
                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='footer-lower'>
                <Container>
                    <Row>
                        <Col lg="6">
                            <p>Copyright © Kaptec - 2022. All Rights Reserved.</p>
                        </Col>
                        <Col lg="6">
                            <p>Created by <span><a href="https://www.novamedia.agency/en" target="_blank">nova media.</a></span></p>
                        </Col>
                    </Row>
                </Container>
            </div>
        </footer>
    )
}

export default Footer