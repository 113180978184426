import React from 'react';
import {
  Routes,
  Route,
} from "react-router-dom";

import { Home, About, Page404 } from 'pages';
import { Navigation, Footer } from 'components';

// If it's multilanguage
// String.prototype.translate = function(lang) {
//   if (langs[lang] && langs[lang][this]){
//     return langs[lang][this];
//   }
//   return this;
// }

const App = () => {

  return (
    <div className='page'>
      <Navigation />
      <Routes>
        <Route path='/' element={<Home />} />
        {/* <Route path='/about' element={<About />} /> */}
        {/* <Route path='*' element={<Page404 />} /> */}
      </Routes>
      <Footer />
    </div>
  );
}

export default App;

