import React from 'react';

import {
    Container,
    Row,
    Col
} from 'reactstrap';

import Isvg from 'react-inlinesvg';

import featureImage1 from 'assets/img/feature1.png';
import featureImage2 from 'assets/img/feature2.png';
import featureImage3 from 'assets/img/feature3.png';
import arrowRightIcon from 'assets/svg/arrow-right.svg';

import c1 from 'assets/svg/c-1.svg';
import c2 from 'assets/svg/c-2.svg';
import c3 from 'assets/svg/c-3.svg';
import c4 from 'assets/svg/c-4.svg';
import c5 from 'assets/svg/c-5.svg';
import c6 from 'assets/svg/c-6.svg';
import c7 from 'assets/svg/c-7.svg';
import c8 from 'assets/svg/c-8.svg';
import c9 from 'assets/svg/c-9.svg';
import c10 from 'assets/svg/c-10.svg';
import c11 from 'assets/svg/c-11.svg';
import c12 from 'assets/svg/c-12.svg';

import quoteUpIcon from 'assets/svg/quote-up.svg';
import quoteDownIcon from 'assets/svg/quote-down.svg';
import kIcon from 'assets/img/k.png';
import ctaImage from 'assets/img/cta.jpg';


import MapContainer from 'components/MapContainer';

import Video from 'components/Video';

const clients = [
    {
        id: 1,
        src: c1,
    },
    {
        id: 2,
        src: c2,
    },
    {
        id: 3,
        src: c3,
    },
    {
        id: 4,
        src: c4,
    },
    {
        id: 5,
        src: c5,
    },
    {
        id: 6,
        src: c6,
    },
    {
        id: 7,
        src: c7,
    },
    {
        id: 8,
        src: c8,
    },
    {
        id: 9,
        src: c9,
    },
    {
        id: 10,
        src: c10,
    },
    {
        id: 11,
        src: c11,
    },
    {
        id: 12,
        src: c12,
    },
]

const Home = () => {
    return (
        <div className='home-page'>

            <header className='home'>
            <Video className="home-video"/>

              
                <Container>
                    <div className='home-text'>
                        <h1>Experts <span>in</span> <span>Unified Communications</span> <span>&</span> <span>Cloud Solutions</span></h1>
                    </div>
                </Container>
            </header>
           
            <section className='features'>
                <span className="h-text h-text-1">SIMPLIFY</span>
                <span className='h-text h-text-2'>TRANSFORM</span>

                <Container>

                    <div className='feature-row'>
                        <div className='image'>
                            <img src={featureImage1} alt='Modern workplace' />
                        </div>
                        <div className='text'>
                            <div className='text-container'>
                                <h6>Modern Workplace</h6>
                                <p>Improve employee productivity and
                                    satisfaction. Create more seamless
                                    communication and collaboration
                                    across locations and platforms, while
                                    maintaining the security and integrity
                                    of systems and data.
                                </p>
                                <button><Isvg src={arrowRightIcon} />Learn more</button>
                            </div>
                        </div>
                    </div>

                    <div className='feature-row'>
                        <div className='text'>
                            <div className='text-container'>

                                <h6>What is the Future for Contact Centres?</h6>
                                <p>In this article, we share best practices
                                    and discuss how you can adapt your
                                    business strategy to address changing
                                    consumer behaviours and employee
                                    expectations.
                                </p>
                                <button><Isvg src={arrowRightIcon} />Learn more</button>
                            </div>
                        </div>
                        <div className='image'>
                            <img src={featureImage2} alt='Modern workplace' />
                        </div>
                    </div>

                    <div className='feature-row'>
                        <div className='image'>
                            <img src={featureImage3} alt='Modern workplace' />
                        </div>
                        <div className='text'>
                            <div className='text-container'>
                                <h6>Telephony in the Hospitality Industry</h6>
                                <p>In the hospitality industry, quality
                                    guest services are critical. Read how we
                                    helped the Hodson Bay Hotel Group
                                    upgrade its telephony system the
                                    heartbeat of its business.
                                </p>
                                <button><Isvg src={arrowRightIcon} />Learn more</button>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>

            <section className='map'>
                <span className='h-text'>GROW</span>
                <Container>
                    <Row>
                        <Col lg="12">
                            <div className='map-title'>
                                <h2 className='heading'>Global Reach</h2>
                                <p>There are no limits to your business growth. We provide global services through our
                                    professional partner network.</p>
                            </div>

                            <MapContainer />
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='clients'>
                <h2 className='heading'>Our Clients</h2>
                <Container>
                    <Row>
                        {clients && clients.map((client, idx) => {
                            console.log(client)
                            return (
                                <Col lg="3" sm="6" key={client.id}>
                                    <img src={client.src} className='client' key={idx} alt="slika" />
                                </Col>
                            )
                        })}
                    </Row>
                </Container>
            </section>

            <section className='testimonials'>
                <img src={kIcon} alt="img"/>
                <Container>
                    <Row>
                        <Col lg="8">
                            <Isvg src={quoteUpIcon} className='quote-up' />
                            <Isvg src={quoteDownIcon} className='quote-down' />

                            <div className='testimonial'>
                                <h3>Kaptec are easy to work with, flexible and experts in their
                                    field. We know we can rely on them to provide the right
                                    solution for our business. business.
                                </h3>
                                <h6>Hodson Bay Group</h6>
                                <p>James Duane</p>
                                <p>Group IT Manager</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='contact'>
                <Container>
                    <Row>
                        <Col lg="12">
                            <div className='contact-container'>
                                <img src={ctaImage}  alt="img"/>
                                <div className='text'>
                                    <h6>Ready to <span>Simplify</span> <span>Transform</span> <span>Grow</span> your business?</h6>
                                    <button><Isvg src={arrowRightIcon} />Contact us now!</button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

        </div>
    )
}

export default Home;