import React from 'react'
import video from "../assets/video/HomeVideo.mp4";
// import useWindowSize from 'hooks/useWindowSize';

const Video = () => {
  // const {w, h} = useWindowSize();
  return (
    <div style={{width: '100%', height: '100%'}}>
    <video  autoPlay muted loop style={{width: '100%', height: '100%', objectFit:'cover'}}>
    <source src={video} type="video/mp4"/>

    Your browser does not support the video tag.
</video>
    </div>

  )
}

export default Video
