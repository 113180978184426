import { createContext, useState } from 'react';

const Context = createContext({});

// USE CASE:
//  import useStore from 'hooks/useStore';
//  const {user, socket, ...} = useStore();

export const ContextProvider = ({children}) => {
    //const [user, setUser] = useState(null);
    //const [socket, setSocket] = useState(null);
    //const [verifyingUser, setVerifyingUser] = useState(true);
    const [lang, setLang] = useState('en');


    let value = {
        //user,
        //setUser,
        //socket,
        //setSocket,
        //verifyingUser,
        //setVerifyingUser,
        lang,
        setLang
    }

    return (
        <Context.Provider value={value}>
            {children}
        </Context.Provider>
    )
}

export default Context;