import React, { useState, useEffect } from 'react'
import { Link, useLocation  } from 'react-router-dom';

import Isvg from 'react-inlinesvg';
import logoIcon from 'assets/svg/logo.svg';
import headphoneIcon from 'assets/svg/headphone.svg';
import linkedinIcon from 'assets/svg/linkedin.svg';
import shapeIcon from 'assets/img/shape.png';
import barsIcon from 'assets/svg/bars.svg';
import arrowDownIcon from 'assets/svg/arrow-down.svg';

import {
    Container,
    Row,
    Col
} from 'reactstrap';

const menuItems = [
    {
        id: 1,
        name: 'Home',
        path: '/'
    },
    {
        id: 2,
        name: 'About Us',
        path: null,
    },
    {
        id: 3,
        name: 'Telecoms',
        path: null,
        dropdown: true,
    },
    {
        id: 4,
        name: 'Modern Workplace',
        path: null,
        dropdown: true,
    },
    {
        id: 5,
        name: 'Outsourcing',
        path: null,
    },
]

const Navigation = () => {
    const [menu, setMenu] = useState();
    const location = useLocation();

    const toggleMenu = () => {
        console.log('test')
        setMenu(prev => !prev)
    }

    useEffect(() => {
        setMenu(false)
      }, [location]);

    return (
        <nav className='navigation'>
            <div className='logo'>
                <Link to='/'>
                    <Isvg src={logoIcon} alt='logo' className='logo-icon' />
                </Link>
            </div>
            <div className={menu ? 'navigation-menu mobile' : 'navigation-menu'}>
                <ul className='menu'>
                    {menuItems && menuItems.map((menuItem) => {
                        return (
                            <li key={menuItem.id}>
                                <Link to={menuItem.path ? menuItem.path : '/'}>{menuItem.name}</Link>
                                {menuItem.dropdown && <Isvg src={arrowDownIcon} />}
                            </li>
                        )
                    })}
                </ul>

                <div className='more'>
                    <a><span><Isvg src={headphoneIcon} alt='headphone' /></span> Contact</a>
                    <a>Client Login</a>
                </div>
                <span className='close' onClick={toggleMenu}>x</span>
            </div>
            <div className='helper'>
                <Link to='/'>
                    <Isvg src={linkedinIcon} className='linkedin-icon' alt='linkedin' />
                </Link>
                <img src={shapeIcon} className='shape' alt='shape' />
            </div>
            <Isvg src={barsIcon} className='menu-icon' onClick={toggleMenu} />
        </nav>
    )
}

export default Navigation