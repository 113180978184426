import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const mapStyles = {
  height: "540px",
  width: "100%",
};

const defaultCenter = {
  lat: 41.3851,
  lng: 2.1734,
};

const markers = [
  {
    lat: 53.8529394,
    lng: -9.2944656,
  },
  {
    lat: 52.4142568,
    lng: -1.786962,
  },
];
const onLoad = (marker) => {
  
};
const MapContainer = () => {
  return (
    <LoadScript googleMapsApiKey="AIzaSyDgnnMn7R8rxiCP8Bvkmfll2FN_6RhlHLQ">
      <GoogleMap mapContainerStyle={mapStyles} zoom={2} center={defaultCenter}>
        {markers.map((marker, midx) => {
          return <Marker onLoad={onLoad} key={midx} position={marker} />;
        })}
      </GoogleMap>
    </LoadScript>
  );
};

export default MapContainer;
